const facts = [
  "The heads on Easter Island have bodies.",
  "The moon has moonquakes.",
  "Goosebumps are meant to ward off predators.",
  "There's no such thing as \"pear cider.\"",
  "Pineapple works as a natural meat tenderizer.",
  "Humans are the only animals that blush.",
  "The feeling of getting lost inside a mall is known as the Gruen transfer.",
  "The wood frog can hold its pee for up to eight months.",
  "The hottest spot on the planet is in Libya.",
  "You lose up to 30 percent of your taste buds during flight.",
  "Your nostrils work one at a time.",
  "Only two mammals like spicy food: humans and the tree shrew.",
  "A chef's toque contains 100 folds.",
  "Rabbits can't puke.",
  "The \"M's\" in M&Ms stand for \"Mars\" and \"Murrie.\"",
  "The human body literally glows.",
  "Copper door knobs are self-disinfecting.",
  "Cotton candy was invented by a dentist.",
  "Marie Curie is the only person to earn a Nobel prize in two different sciences.",
  "Fingernails don't grow after you die.",
  "The English word with the most definitions is \"set.\"",
  "Creedence Clearwater Revival has the most No. 2 <em>Billboard</em> hits—without ever hitting No. 1.",
  "Pigeons can tell the difference between a painting by Monet and Picasso.",
  "The dot over the lower case \"i\" or \"j\" is known as a \"tittle.\"",
  "Chewing gum boosts concentration.",
  "Superman didn't always fly.",
  "The first computer was invented in the 1940s.",
  "Space smells like seared steak.",
  "The longest wedding veil was the same length as 63.5 football fields.",
  "The unicorn is the national animal of Scotland.",
  "Bees sometimes sting other bees.",
  "Kids ask 300 questions a day.",
  "The total weight of ants on earth once equaled the total weight of people.",
  "\"E\" is the most common letter and appears in 11 percent of all english words.",
  "A dozen bodies were once found in Benjamin Franklin's basement.",
  "The healthiest place in the world is in Panama.",
  "A pharaoh once lathered his slaves in honey to keep bugs away from him.",
  "Some people have an extra bone in their knee (and it's getting more common).",
  "Pringles aren't actually potato chips.",
  "There's a giant fish with a transparent head.",
  "There's a decorated war hero dog.",
  "Showers really do spark creativity.",
  "Abraham Lincoln's bodyguard left his post at Ford's Theatre to go for a drink.",
  "Dolphins have been trained to be used in wars.",
  "Playing the accordion was once required for teachers in North Korea.",
  "Children's medicine once contained morphine.",
  "Plastic Easter eggs and plastic Easter grass were invented by a man who holds more patents than Thomas Edison.",
  "Water makes different pouring sounds depending on its temperature.",
  "Most laughter isn't because things are funny.",
  "One man has saved more than 200 people from suicide.",
  "Our European ancestors were cannibals.",
  "Dogs actually understand some English.",
  "You have a nail in your body.",
  "Redheads aren't actually going extinct.",
  "Pro baseball once had women players.",
  "Koalas have fingerprints.",
  "Humans are just one of the estimated 8.7 million species on Earth.",
  "Riding a roller coaster could help you pass a kidney stone.",
  "Dinosaurs lived on every continent.",
  "Bee hummingbirds are so small they get mistaken for insects.",
  "Sea lions can dance to a beat.",
  "Rolls-Royce makes the most expensive car in the world.",
  "The famed \"Typhoid Mary\" infected more than 50 people by cooking for them.",
  "The legend of the Loch Ness Monster goes back nearly 1,500 years.",
  "Nutmeg can be fatally poisonous.",
  "Chinese police use geese squads.",
  "The first iPhone wasn't made by Apple.",
  "There's a country where twins are most likely to be born.",
  "The Comic Sans font came from an actual comic book.",
  "For 100 years, maps have shown an island that doesn't exist.",
  "Babies are physical anomalies.",
  "The Queen has some hidden hideaways.",
  "The man who wrote <i>Dracula n</i>ever visited Transylvania.",
  "The Australian government banned the word \"mate\" for a day.",
  "Many languages have the same roots.",
  "A tick bite can make you allergic to red meat.",
  "Harriet Tubman was even more heroic than you thought.",
  "Tornadoes can cause \"fish rain.\"",
  "Napoleon was once attacked by thousands of rabbits.",
  "<i>Star Trek</i>'s Scotty stormed the beach at Normandy.",
  "Apple Pie isn't actually American.",
  "Pigs are constitutionally protected in Florida.",
  "Mr. Cherry breaks all the records you've never heard of.",
  "Sweat doesn't actually stink.",
  "Some planets produce diamond rain.",
  "Sharks can live for five centuries.",
  "There's an entire town under a rock.",
  "It is illegal to sell a \"bounceless\" pickle to somebody in Connecticut.",
  "The Bermuda Triangle isn't any more likely to cause a mysterious disappearance than anywhere else.",
  "There's a world record—and a happy ending—for the greatest distance thrown in a car accident.",
  "You can sneeze faster than a cheetah can run.",
  "The fire hydrant patent was lost in a fire.",
  "Saudi Arabia imports camels from Australia.",
  "One man once survived two atomic bombs.",
  "The cast of <i>Friends </i>still earns around $20 million each year.",
  "Pluto technically isn't even a year old.",
  "Cows kill more Americans each year than sharks do.",
  "Newborns don't have kneecaps.",
  "In Germany, people help toads cross the road.",
  "Cheetahs don't roar.",
  "France has a dozen time zones.",
  "Humans aren't the only species to adopt.",
  "Blue Ivy Carter is the youngest person ever to appear on a <em>Billboard</em> chart.",
  "The majority of Americans choose dogs over love.",
  "The first <i>Star Wars</i> was expected to be a flop.",
  "Your liver can regrow itself in three weeks.",
  "Danes once bred a pig to look like the flag.",
  "A 70-year-old woman once completed seven marathons in seven days, across all seven continents.",
  "Dogs like squeaky toys for a dark reason.",
  "The man who founded Atari also started Chuck E. Cheese.",
  "One man was once constipated for nearly two months.",
  "Most people break up on Mondays.",
  "Beethoven could still hear after going deaf.",
  "Ants have a built-in FitBit.",
  "Stressed men have altered proclivities.",
  "Crows holds grudges.",
  "Canada once heavily targeted LGBTQ individuals.",
  "Bill Gates has donated nearly half his fortune.",
  "You can always \"see\" your nose.",
  "A restaurant in New York employs grandmas as chefs.",
  "Shaq only ever made one three-pointer.",
  "America's first bank robber deposited the money back into the same bank.",
  "Germany uncovers 2,000 tons of unexploded bombs every year.",
  "Sharks existed before trees.",
  "And trees weren't always biodegradable.",
  "Detroit undercover cops in a drug ring once fought another group of undercover cops.",
  "You're pronouncing Dr. Seuss' name wrong.",
  "Nearly all species to have ever existed on Earth are extinct.",
  "The Silverback gorilla can lift almost a literal ton.",
  "Every time you shuffle a deck of cards, you get a combination that's never existed.",
  "There is an immortal jellyfish.",
  "America accidentally dropped an atom bomb on South Carolina in 1958.",
  "Baby blue whales grow 200 pounds per day.",
  "Actor Bill Murray uses a 1-800 number instead of an agent or manager.",
  "Cats once delivered mail in Belgium.",
  "It's a myth that people are either \"left-brained\" or \"right-brained.\"",
  "Women are attracted to the scent of Good & Plenty licorice candy and cucumber.",
  "There's a device that creates energy from snowfall.",
  "A mermaid \"documentary\" once fooled so many people that the U.S. government had to issue a statement.",
  "The Iowa State Fair holds quirky competitions like a beard-growing contest and a husband-calling contest.",
  "There's a textbook written entirely by an AI author.",
  "Earth won't always have the same North Star.",
  "There's an entire family in Italy that feels almost no pain.",
  "The word \"hipster\" goes all the way back to the 1930s.",
  "Guinea pigs were once sacrificed wearing earrings and necklaces and wrapped like sushi.",
  "Hitler had a flatulence problem.",
  "Scientists were able to take a picture of an atom's shadow.",
  "Maine is the only state that borders just one other state.",
  "The Twitter bird's official name Is Larry.",
  "The longest book title contains 1,809 words.",
  "Allergy season is getting longer and more intense each year.",
  "There's a reason there's a hole in your pen caps.",
  "You can learn the High Valyrian language from <em>Game of Thrones</em> with an online course.",
  "Some Maryland residents are taxed for the rain.",
  "Scientists have partially revived disembodied pig brains.",
  "Astronauts in space are exposed to the same amount of radiation as 150 to 6,000 chest x-rays.",
  "Beaver bum goo is occasionally used to enhance vanilla flavorings.",
  "The U.S. almost went to war with Canada over a pig.",
  "Tornados used to be called \"twirlblasts\" and \"twirlwinds\" in the 18th century.",
  "The Sundance Kid took his nickname from the town of Sundance, Wyoming.",
  "Eating your offspring may be a sign of good parenting in some species.",
  "The word \"MacGyvered\" is in the Oxford English Dictionary.",
  "Kentucky has more bourbon than people.",
  "Jupiter's red spot is getting taller and smaller at the same time.",
  "Three eagles were found co-parenting three eaglets in Illinois.",
  "There is a town in Nebraska with a population of one.",
  "When President Carter pardoned draft dodgers, only half returned.",
  "The Amazon River Dolphin's brain capacity is 40 percent larger than humans'.",
  "There's a Scottish term for hesitating when introducing someone because you've forgotten their name.",
  "Earth is hit with more energy from the sun each hour than the planet uses in a year.",
  "The Queen celebrates two birthdays every year.",
  "Scientists can identify the brain waves that signal someone is having an angry dream.",
  "McRib sandwiches contain about 70 ingredients.",
  "The Apollo astronauts' footprints on the Moon could stay there for 100 million years."
];

export default facts;
